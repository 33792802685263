<template>
  <div class="anthro-results-settings-page">
    <div class="txt-16 txt-font-din-medium settings-page-header mt-20 ml-4">
      {{ $t('components.titles.dataEntrySettings') }}
    </div>
    <a-card :bordered="true" class="mt-20">
      <div class="txt-20 txt-font-din-medium txt-black">{{ $t('components.labels.ageFormat') }}</div>
      <p>{{ $t('components.description.selectAgeFormat') }}</p>
        <a-radio-group v-model="settings.ageFormat" :disabled="isSupervisor" name="age_format">
          <a-radio value="birthday" class="mr-20">
            {{ $t('components.dropdown.withBirthday') }}
          </a-radio>
          <a-radio value="months">
            {{ $t('components.dropdown.withMonths') }}
          </a-radio>
        </a-radio-group>
    </a-card>
    <a-card :bordered="true" class="mt-20">
      <div class="txt-20 txt-font-din-medium txt-black">{{ $t('components.labels.autopopulation') }}</div>
      <p>{{ $t('components.description.selectAutoFill') }}</p>
      <a-checkbox-group v-model="settings.autopopulation" :disabled="isSupervisor">
        <a-row class="mb-8 w350">
          <a-col :span="12">
            <a-checkbox value="survDate">
              {{ $t('components.dropdown.surveyDate') }}
            </a-checkbox>
          </a-col>
          <a-col :span="12">
            <a-checkbox value="cluster">
              {{ $tc('components.dropdown.cluster', 1) }}
            </a-checkbox>
          </a-col>
        </a-row>
        <a-row class="mb-8 w350">
          <a-col :span="12">
            <a-checkbox value="team">
              {{ $t('components.dropdown.teamNumber') }}
            </a-checkbox>
          </a-col>
          <a-col :span="12">
            <a-checkbox value="id">
              {{ $t('components.dropdown.id') }}
            </a-checkbox>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-checkbox value="hh">
              {{ $t('components.dropdown.householdNumber') }}
            </a-checkbox>
          </a-col>
        </a-row>
      </a-checkbox-group>
    </a-card>
    <a-card :bordered="true" class="mt-20">
      <div class="txt-20 txt-font-din-medium mb-20 txt-black">
        {{ $t('components.labels.clothing') }}
        <a-tooltip :title="$t('components.toolTips.clothing')" class="ml-8">
          <a-icon type="info-circle" class="pt-0" style="color: #c6c7c6; font-size: 20px"/>
        </a-tooltip>
        </div>
      <div class="txt-12 mt-20 mb-4">{{ $t('components.dropdown.weightForSubtraction') }}</div>
      <div>
        <a-input-number v-model="settings.clothingWeight" :min="0" :disabled="isSupervisor"
          :class="['w70 mr-4', settings.clothingWeight !== null ? null : 'missing-value']"/> {{ $tc('components.description.gram', settings.clothingWeight) }}
      </div>
    </a-card>
    <a-card :bordered="true" class="mt-20">
      <div class="txt-20 txt-font-din-medium txt-black">
        {{ $t('components.labels.variableRanges') }}
        <a-tooltip :title="$t('components.toolTips.variableRange')" class="ml-8">
          <a-icon type="info-circle" class="pt-0" style="color: #c6c7c6; font-size: 20px"/>
        </a-tooltip>
        </div>
      <div class="w450 mt-20">
        <div class="border-bottom-grey flex w100">
          <div class="w50 txt-grey">{{ $t('components.labels.name') }}</div>
          <div class="w50 txt-grey">{{ $t('components.labels.range') }}</div>
        </div>
        <div
          v-for="(range, index) in settings.variableRanges"
          :key="index"
          class="border-bottom-grey flex w100 pt-10 pb-10 flex align-center">
          <div v-if="!range.custom" class="w50 ">{{ translateLetter(range.name, range.unit) }}</div>
          <div v-else class="w50">
            <a-input
              v-model="settings.variableRanges[index].name"
              :disabled="isSupervisor"
              class="mr-10 w200"
              placeholder="Enter Variable Name"/>
          </div>
          <div class="w50 flex align-center">
            <a-input-number
              v-model="settings.variableRanges[index].start"
              :disabled="isSupervisor"
              :class="['w60', settings.variableRanges[index].start !== null ? null : 'missing-value']"/>
            <div class="ml-4 mr-4">{{ $t('values.to') }}</div>
            <a-input-number
              v-model="settings.variableRanges[index].end"
              :disabled="isSupervisor"
              :class="['w60', settings.variableRanges[index].end !== null ? null : 'missing-value']"/>
            <a-button
              v-if="range.custom && !isSupervisor" class="ml-8" type="danger" shape="circle" icon="delete"
              :disabled="isSupervisor"
              @click="deleteVariableRate(index)"/>
          </div>
        </div>
      </div>
      <!-- <a-button type="link"
        class="mt-20 txt-orange txt-semi-bold
          txt-font-din-medium letter-spacing flex align-center"
        @click="addVariableRate">
        <a-icon
          type="plus-circle"
          theme="filled"
          class="txt-orange mr-4"
          style="font-size: 20px;"/>
        Add New Variable Range
      </a-button> -->
    </a-card>
    <div class="txt-16 txt-font-din-medium settings-page-header mt-40">{{ $t('components.titles.resultSettings') }}</div>
    <a-card :bordered="true" class="mt-20 age-range">
      <div class="txt-20 txt-font-din-medium txt-black">
        {{ $t('components.labels.ageGroupMonths') }}
        <a-tooltip :title="$t('components.toolTips.ageGroups')" class="ml-8">
          <a-icon type="info-circle" class="pt-0" style="color: #c6c7c6; font-size: 20px"/>
        </a-tooltip>
        </div>
      <p>{{ $t('components.description.editableAgeRanges') }}</p>
      <div
        v-for="(group, index) in settings.ageGroups"
        :key="index"
        :class="['flex align-center mt-4 mb-4 hover']"
        @mouseover="isHovering = true"
        @mouseleave="isHovering = false">
        <a-input-number v-model="settings.ageGroups[index].start"
          :disabled="isSupervisor"
          :min="index > 0 && settings.ageGroups[index -1].end != null ? settings.ageGroups[index -1].end : 0"
          :class="['w60', settings.ageGroups[index].start !== null ? null : 'missing-value']"/>
        <div class="ml-4 mr-4">-</div>

        <a-input-number v-model="settings.ageGroups[index].end"
            :disabled="index < settings.ageGroups.length - 1 || isSupervisor"
            :min="index < settings.ageGroups.length - 1 ? settings.ageGroups[index + 1].start - 1 :
              index == settings.ageGroups.length - 1 ? settings.ageGroups[index].start : 0"
            :max="index < settings.ageGroups.length - 1 && settings.ageGroups[index + 1].start - 1 ?
              settings.ageGroups[index + 1].start - 1 :
              undefined"
            :class="['w60', settings.ageGroups[index].end !== null ? null : 'missing-value']"/>
        <a-button
          v-if="index !== 0 && !isSupervisor" class="ml-8 delete" type="danger" shape="circle" icon="delete" :disabled="isSupervisor"
          @click="deleteAgeGroup(index)"/>
      </div>
      <a-button type="link" :disabled="isSupervisor"
        class="mt-20 txt-orange txt-semi-bold
          txt-font-din-medium letter-spacing flex align-center"
        @click="addAgeGroup">
        <a-icon
          type="plus-circle"
          theme="filled"
          class="mr-4"
          :class="{ 'text-orange': !isSupervisor }"
          style="font-size: 20px;"/>
        {{ $t('components.description.addNewAgeGroup') }}
      </a-button>
    </a-card>
    <a-card :bordered="true" class="mt-20">
      <div class="txt-20 txt-font-din-medium txt-black">
        {{ $t('components.labels.restrictedAnalysis') }}
        <a-tooltip :title="$t('components.toolTips.restrictedAnalysis')" class="ml-8">
          <a-icon type="info-circle" class="pt-0" style="color: #c6c7c6; font-size: 20px"/>
        </a-tooltip>
        </div>
      <p>
        {{ $t('components.description.selectRangeMonths') }}
      </p>
      <div v-for="(restrict, index) in settings.restricted" :key="index" class="restricted-container">
        <a-checkbox v-model="settings.restricted[index].selected" :disabled="isSupervisor" class="restricted-checkbox"/>
        <a-input-number v-model="settings.restricted[index].start" :disabled="isSupervisor"
          :min="0"
          :class="['w60 ml-8 mr-4', settings.restricted[index].start !== null ? null : 'missing-value']"/>
        <div class="ml-4 mr-4">{{ $t('values.to') }}</div>
        <a-input-number v-model="settings.restricted[index].end" :disabled="isSupervisor"
          :min="settings.restricted[index].start + 1"
          :class="['w60 ml-4 mr-8', settings.restricted[index].end !== null ? null : 'missing-value']"/>
        <div class="ml-4 mr-4">{{ translateMetric(settings.restricted[index].name) }}</div>
      </div>
    </a-card>
    <a-card :bordered="true" class="mt-20">
      <div class="txt-20 txt-font-din-medium mb-20 txt-black">
        {{ $t('components.labels.muacCutOffs') }}
        <a-tooltip :title="$t('components.toolTips.muacCutOffs')" class="ml-8">
          <a-icon type="info-circle" class="pt-0" style="color: #c6c7c6; font-size: 20px"/>
        </a-tooltip>
        </div>
      <div class="mb-10">
        <a-input-number v-model="settings.muac_lwr" :min="0" :max="Math.max(muacUpr - 1, 0)" :disabled="isSupervisor"
            :class="['w70 mr-4', muacLwr !== null ? null : 'missing-value']"/> {{ $t('values.mm') }}
      </div>
      <div>
        <a-input-number v-model="settings.muac_upr" :min="0" :disabled="isSupervisor"
          :class="['w70 mr-4', muacUpr !== null ? null : 'missing-value']"/> {{ $t('values.mm') }}
      </div>
    </a-card>
    <a-card :bordered="true" class="mt-20">
      <div class="txt-20 txt-font-din-medium txt-black">
        {{ $t('components.labels.zScoreExclusion') }}
        <a-tooltip :title="$t('components.toolTips.zscoreExclusion')" class="ml-8">
          <a-icon type="info-circle" class="pt-0" style="color: #c6c7c6; font-size: 20px"/>
        </a-tooltip>
        </div>
      <p>{{ $t('components.description.selectZScoreExclusion') }}</p>
      <a-radio-group v-model="settings.zScoreExclusion" :disabled="isSupervisor" name="z-score-exclusion" class="w635">
        <a-row class="mt-20">
          <a-col :span="12">
            <div class="txt-18 txt-black">{{ $t('components.labels.smartFlags') }}</div>
            <div
              v-for="(value, key) of settings.zScoreValues.smart" :key="key"
              class="flex align-center mt-8 mb-8">
              <div class="txt-uppercase mr-10 w70">{{ translateKey(key) }}</div>
              <a-input-number v-model="settings.zScoreValues.smart[key].start" :disabled="isSupervisor"
                :class="['w60', settings.zScoreValues.smart[key].start !== null ? null : 'missing-value']"/>
              <div class="ml-4 mr-4">{{ $t('values.to') }}</div>
              <a-input-number v-model="settings.zScoreValues.smart[key].end" :disabled="isSupervisor"
                :class="['w60', settings.zScoreValues.smart[key].end !== null ? null : 'missing-value']"/>
            </div>
          </a-col>
          <a-col :span="12">
             <div class="txt-18 txt-black">{{ $t('components.labels.whoFlags') }}</div>
            <div
              v-for="(value, key) of settings.zScoreValues.who" :key="key"
              class="flex align-center mt-8 mb-8">
              <div class="txt-uppercase mr-10 w70">{{ translateKey(key) }}</div>
              <a-input-number v-model="settings.zScoreValues.who[key].start" :disabled="isSupervisor"
                :class="['w60', settings.zScoreValues.who[key].start !== null ? null : 'missing-value']"/>
              <div class="ml-4 mr-4">{{ $t('values.to') }}</div>
              <a-input-number v-model="settings.zScoreValues.who[key].end" :disabled="isSupervisor"
                :class="['w60', settings.zScoreValues.who[key].end !== null ? null : 'missing-value']"/>
            </div>
          </a-col>
        </a-row>
      </a-radio-group>
    </a-card>
    <div class="mt-40 mb-40">
      <a-button
        type="primary"
        size="large"
        :disabled="isSupervisor"
        @click="save">
        {{ $t('components.description.saveSettings') }}
      </a-button>
      <a-popconfirm
        title="Are you sure you want to reset your settings?"
        :ok-text="$t('values.yes')"
        :cancel-text="$t('values.no')"
        :disabled="isSupervisor"
        @confirm="reset">
        <a-button
          class="ml-20"
          type="danger"
          :disabled="isSupervisor"
          size="large">
          {{ $t('components.description.resetSettings') }}
        </a-button>
      </a-popconfirm>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';

// how long to wait before updating muac in ms
const debounceDelay = 1000;

export default {
  name: 'AnthropometrySettings',
  data() {
    return {
      settings: { ...this.$store.state.results.anthroSettings },
      isHovering: false,
      muacLwr: this.$store.state.results.anthroSettings.muac_lwr,
      muacUpr: this.$store.state.results.anthroSettings.muac_upr,
      activeAlerts: []
    };
  },
  computed: {
    ...mapState({
      anthroSettings: state => state.results.anthroSettings,
      surveyId: state => state.survey.surveyId,
      projectId: state => state.survey.project.id,
      metadata: state => state.survey.metadata,
      isSupervisor: state => state.survey.currentUserRoleSystem === 'srvy-sup'
    }),
    clothingIsValid() {
      return (
        this.settings.clothingWeight !== null
      );
    },
    isVariableRangeValid() {
      return this.settings.variableRanges.every(item => item.start !== null && item.end !== null);
    },
    isAgeGroupsValid() {
      return this.settings.ageGroups.every(item => item.start !== null && item.end !== null);
    },
    isRestrictedAnalysisValid() {
      return this.settings.restricted.every(item => item.start !== null && item.end !== null);
    },
    isMuacValid() {
      return this.settings.muac_lwr !== null &&
        this.settings.muac_upr !== null;
    },
    iszScoreExclusionValid() {
      for (const [, value] of Object.entries(this.settings.zScoreValues.smart)) {
        if (value.start === null || value.end === null) {
          return false;
        }
      }
      for (const [, value] of Object.entries(this.settings.zScoreValues.who)) {
        if (value.start === null || value.end === null) {
          return false;
        }
      }
      return true;
    }
  },
  watch: {
    anthroSettings(settings) {
      this.settings = isEmpty(settings) ? this.settings : { ...settings };
    },
    'settings.muac_lwr': debounce(function(val) {
      this.muacLwr = val;
    }, debounceDelay),
    'settings.muac_upr': debounce(function(val) {
      this.muacUpr = val;
    }, debounceDelay)
  },
  mounted() {
    this.settings = isEmpty(this.anthroSettings) ? this.settings : { ...this.anthroSettings };
  },
  methods: {
    ...mapActions(['saveAnthropometrySettings']),
    translateMetric(metric) {
      const metrics = {
        cm: this.$t('values.cm'),
        months: this.$tc('components.description.month', 2).toLowerCase()
      };
      return metrics[metric];
    },
    translateKey(name) {
      const variableNames = {
        whz: this.$t('components.description.whz'),
        haz: this.$t('components.description.haz'),
        waz: this.$t('components.description.waz'),
        bmiz: this.$t('components.description.bmiz'),
        muacz: this.$t('components.description.muacz')
      };
      return variableNames[name];
    },
    translateLetter(name, unit) {
      const variableNames = {
        months: this.$tc('components.description.month', 2),
        weight: this.$t('components.description.weight', { unit }),
        height: this.$t('components.description.height', { unit }),
        muac: this.$t('components.description.muac', { unit })
      };
      return variableNames[name];
    },
    getAlertIndex(id) {
      const index = this.activeAlerts.findIndex(item => item.id === id);
      return index;
    },
    closeAgeSelection() {
      const index = this.getAlertIndex('ageSelection');
      if (index > -1) {
        this.activeAlerts.splice(index, 1);
      }
      this.$emit('activeAlerts', this.activeAlerts);
    },
    formIsValid() {
      return this.clothingIsValid &&
        this.isVariableRangeValid &&
        this.isAgeGroupsValid &&
        this.isRestrictedAnalysisValid &&
        this.isMuacValid &&
        this.iszScoreExclusionValid;
    },
    addVariableRate() {
      this.settings.variableRanges.push({
        name: undefined,
        start: undefined,
        end: undefined,
        custom: true
      });
    },
    deleteVariableRate(index) {
      this.settings.variableRanges = this.settings.variableRanges
        .filter((_, i) => i !== index);
    },
    addAgeGroup() {
      this.settings.ageGroups.push({
        start: undefined,
        end: undefined,
        custom: true
      });
    },
    deleteAgeGroup(index) {
      const { ageGroups } = this.settings;
      if (index + 1 < ageGroups.length) {
        ageGroups[index + 1].start = ageGroups[index - 1].end + 1;
      }
      ageGroups.splice(index, 1);
    },
    async save() {
      const rangeAge = this.settings.restricted.find(item => item.queryKey === 'months');
      const rangeCondition = this.metadata.includedAgeGroup === '6-59' ? (rangeAge.start === 6 && rangeAge.end === 59.99) : (rangeAge.start === 0 && rangeAge.end === 59.99);
      const differentRestrictedAge = rangeAge.selected && !rangeCondition;
      if (differentRestrictedAge) {
        this.activeAlerts.unshift(
          {
            id: 'ageSelection',
            message: this.$t('components.notifications.restrictingAnalysisAgeRange'),
            onClose: this.closeAgeSelection
          }
        );
        this.$emit('activeAlerts', this.activeAlerts);
      }
      if (!this.formIsValid()) {
        this.$alert().danger(this.$t('components.notifications.ensureAllFieldsComplete'));
        return;
      }
      await this.$message.loading({ content: this.$t('components.notifications.savingSettings'), duration: 1 });
      this.saveAnthropometrySettings({
        surveyId: this.surveyId,
        projectId: this.projectId,
        settings: this.settings
      });
      this.$alert().success(this.$t('components.notifications.savedSettings'));
    },
    reset() {
      this.settings = { ...this.$store.state.results.defaultAnthroSettings };
      this.saveAnthropometrySettings({
        surveyId: this.surveyId,
        projectId: this.projectId,
        settings: this.settings
      });
      this.$alert().success(this.$t('components.notifications.resetSettings'));
    }
  }
};
</script>

<style lang="scss">
.settings-page-header {
  color: #8e908f;
}
.anthro-results-settings-page {
  label {
    font-family: 'DINNextLTPro-Regular';
  }
}

.missing-value {
  border:#FF0000 1px solid;
}

.border-bottom-grey {
  border-bottom: 1px solid #e6e6e7;
}

.age-range .delete {
  visibility: collapse;
}

.age-range .hover:hover .delete {
  visibility: visible;
}

.restricted-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  span:nth-child(2) {
    display: flex;
    align-items: center;
  }
}

.restricted-checkbox {
  display: flex;
  align-items: center;
}

</style>
